import { ExtractRouteParams } from '@retail/backoffice-urls';

import { PathName, PathRecord } from '~/constants/route';

import { useHrefCallback } from './useHrefCallback';

export function useHref<Key extends PathName>(
  path: Key,
  options?: Omit<Partial<ExtractRouteParams<PathRecord[Key]>>, 'language'>
) {
  const hrefCallback = useHrefCallback();

  return hrefCallback<Key>(path, options);
}
