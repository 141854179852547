import { FilterType } from '@retail/gql-utils';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDepartmentsAndTeamsOptions } from './useDepartmentsAndTeamsOptions';

export function useSearchDepartmentsAndTeamsOptions() {
  const { t } = useTranslation();
  const { options, isLoading } = useDepartmentsAndTeamsOptions();

  const unassignedOptions = useMemo(
    (): DefaultOptionType[] => [
      {
        label: t('bo.admin.userTeams.option.UNASSIGNED'),
        value: FilterType.IS_NULL
      }
    ],
    [t]
  );

  const optionsWithUnassignedOption = useMemo(() => {
    return unassignedOptions.concat(
      ...options.map((x) => ({
        ...x,
        children: unassignedOptions.concat(
          ...(x.children as DefaultOptionType[])
        )
      }))
    );
  }, [options, unassignedOptions]);

  return {
    options: optionsWithUnassignedOption,
    unassignedOptions,
    isLoading
  };
}
