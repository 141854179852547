import { UserAccessParameterValue } from '~/constants/auxUser/accessParams';
import countryOptions from '~/helpers/fieldOptions/country/options';

export const options = [
  {
    value: UserAccessParameterValue.ALL,
    label: 'ALL'
  },
  ...countryOptions,
  {
    value: UserAccessParameterValue.NONE,
    label: 'No country'
  }
];
