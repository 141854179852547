import { useState } from 'react';

export function useActive(defaultValue = false) {
  const [active, setState] = useState(defaultValue);

  function setActive() {
    setState(true);
  }
  function setInactive() {
    setState(false);
  }

  return {
    active,
    setActive,
    setInactive
  };
}
