import { SortType } from '@retail/gql-utils';

export const DEFAULT_SORTING = [
  {
    property: 'firstName',
    direction: SortType.ASC
  },
  {
    property: 'lastName',
    direction: SortType.ASC
  }
];

export const MIN_SEARCH_LENGTH = 2;
