import { Card } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AntOptionProps,
  DEFAULT_SELECT_ALL_OPTION,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';
import { useHrefCallback } from '~/hooks/useHrefCallback';

export interface IAntOptionProps {
  options: Array<AntOptionProps>;
  isLoading?: boolean;
}

export const AssignRoles = ({ options, isLoading }: IAntOptionProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const getHref = useHrefCallback();

  return (
    <Card
      title={t('bo.admin.groupsForm.assignRoles.title')}
      loading={isLoading}
    >
      <SelectControlled
        required
        allowClear
        allowSelectAll
        selectAllOptionProps={{
          ...DEFAULT_SELECT_ALL_OPTION,
          label: t('bo.admin.select.selectAll')
        }}
        options={options}
        qaSelector="group-roleIds"
        mode="multiple"
        controllerProps={{
          control,
          name: 'roleIds'
        }}
        tagRender={(props) =>
          Tag({ ...props, url: getHref('ROLE', { id: props.value }) })
        }
      />
    </Card>
  );
};
