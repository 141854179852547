import cns from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useHrefCallback } from '~/hooks/useHrefCallback';

import cn from './styles.less';

type TAccessParam = {
  key: string;
  value: string;
};

export interface IItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  country: string;
  isNew: boolean;
  accessParameters: Array<TAccessParam>;
}

export interface ItemProps {
  item: IItem;
  provided: any;
  style?: object;
  isDragging?: boolean;
}

const Item = ({ provided, item, style, isDragging }: ItemProps) => {
  const { t } = useTranslation();
  const { accessParameters } = item as IItem;
  const getHref = useHrefCallback();

  const accessCountries = useMemo(
    () =>
      accessParameters
        ?.filter(({ key }) => key === 'country')
        .map(({ value }) =>
          value === '*' ? t('bo.admin.groupsForm.assignUsers.item.all') : value
        )
        .join(', '),
    [accessParameters, t]
  );

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={{ ...style, ...provided.draggableProps.style }}
      className={cns(cn.item, {
        [cn.isDragging]: isDragging,
        [cn.disabled]: !item.enabled,
        [cn.isNew]: item.isNew
      })}
    >
      <div className={cn.title}>
        <Link className={cn.link} to={getHref('USER', { id: item.id })}>
          {item.firstName} {item.lastName}
        </Link>
      </div>
      <div className={cn.infoRow}>
        <span className={cn.label}>{`${t(
          'bo.admin.groupsForm.assignUsers.item.email'
        )}: `}</span>
        <span>{item.email}</span>
      </div>
      <div className={cn.infoRow}>
        <span className={cn.label}>{`${t(
          'bo.admin.groupsForm.assignUsers.item.accessCountry'
        )}: `}</span>
        <span>
          {accessCountries.length > 0
            ? accessCountries
            : t('bo.admin.groupsForm.assignUsers.item.none')}
        </span>
      </div>
    </div>
  );
};

export default Item;
