import { createFilterPayload, SortType } from '@retail/gql-utils';
import {
  assign,
  difference,
  filter,
  find,
  flow,
  get,
  getOr,
  isEmpty,
  map,
  omit,
  reduce,
  trim
} from 'lodash/fp';

import { RetailAccessParamDto } from '~/apollo/gql-types';
import { UserAccessParameter } from '~/constants/auxUser/accessParams';
import {
  NATTERBOX_SIP_ID,
  TNG_USER,
  TWILIO_ENABLED,
  USER_TEST_DATA_ACCESS
} from '~/constants/auxUser/properties';
import { DOMAINS } from '~/constants/permissions';
import { formatDateTime } from '~/helpers/date';
import { formatUser } from '~/helpers/user';

export const USER_FORM_BASE_OPTIONS = {
  context: {
    headers: { 'access-domain': DOMAINS.USER_MANAGEMENT }
  }
};

export const groupsSearchParams = {
  search: createFilterPayload({
    sorts: [
      {
        property: 'name',
        direction: SortType.ASC
      }
    ]
  })
};

const AVAILABLE_USER_PROPERTIES = [NATTERBOX_SIP_ID, TNG_USER, TWILIO_ENABLED];

export const formToData =
  (defaultValues, userData, hasGroupAccessPerms) => (formValues) => {
    const oldGroupIds = flow(get(['groups']), map(get(['id'])))(userData);
    const oldPropertiesNames = flow(
      get('properties'),
      map(get('name'))
    )(userData);

    return flow(
      assign(defaultValues),
      omit(['loginTime', 'createdOn', 'createdBy', '__typename']),
      ({ id, countryAccess, groupAccess, groupIds, ...user }) => {
        const deleteGroupIds = difference(oldGroupIds, groupIds);
        const addGroupIds = difference(groupIds, oldGroupIds);
        const { properties, deletePropertyNames } = reduce(
          (accumulator, propertyName) => {
            const properties = getOr([], ['properties'], accumulator);
            const deletePropertyNames = getOr(
              [],
              'deletePropertyNames',
              accumulator
            );
            const isOldProperty = oldPropertiesNames.indexOf(propertyName) > -1;
            const newPropertyValue = flow(
              getOr('', propertyName),
              trim
            )(user as null); //Todo: check for better type
            const shouldPropertyBeDeleted = isOldProperty && !newPropertyValue;

            user = omit(propertyName, user);
            if (shouldPropertyBeDeleted) {
              /**
               * Put the properies in the delete-list if the properties had a value
               * and now it's being empted.
               */
              return {
                properties,
                deletePropertyNames: [...deletePropertyNames, propertyName]
              };
            } else if (newPropertyValue) {
              /**
               * Only if the property has a value it will pushed into the propery
               * array in order to be saved. No empty strings should end up here.
               */
              return {
                deletePropertyNames,
                properties: [
                  ...properties,
                  {
                    name: propertyName,
                    value: newPropertyValue
                  }
                ]
              };
            }

            /**
             * Don't put neither in the delete nor in the properties
             * if the property didn't exists and the user is not updating it
             */
            return accumulator;
          },
          { properties: [], deletePropertyNames: [] },
          AVAILABLE_USER_PROPERTIES
        );

        return {
          id,
          countryAccess,
          groupAccess: groupAccess || [],
          withGroupAccess: hasGroupAccessPerms && !isEmpty(groupAccess),
          deleteGroupIds,
          withDeleteGroup: !isEmpty(deleteGroupIds),
          addGroupIds,
          withAddGroup: !isEmpty(addGroupIds),
          properties,
          withDeleteProperties: !isEmpty(deletePropertyNames),
          withProperties: !isEmpty(properties),
          deletePropertyNames,
          user
        };
      }
    )(formValues);
  };

export function getTestDataAccessPerm(
  accessParameters: RetailAccessParamDto[]
) {
  return flow(
    find({ key: USER_TEST_DATA_ACCESS }),
    getOr('false', 'value'),
    (value) => value === 'true'
  )(accessParameters);
}

export const formFromData = (defaultValues) =>
  flow(
    assign(defaultValues),
    ({
      createdOn,
      createdBy,
      loginTime,
      accessParameters,
      groups,
      properties = [],
      ...fields
    }) => ({
      ...fields,
      createdBy: formatUser(createdBy),
      createdOn: createdOn && formatDateTime(createdOn),
      loginTime: loginTime && formatDateTime(loginTime),
      groupIds: map(get(['id']), groups),
      countryAccess: flow(
        filter({ key: UserAccessParameter.COUNTRY }),
        map(get(['value']))
      )(accessParameters),
      groupAccess: flow(
        filter({ key: UserAccessParameter.GROUP }),
        map(get(['value']))
      )(accessParameters),
      [NATTERBOX_SIP_ID]: flow(
        find({ name: NATTERBOX_SIP_ID }),
        getOr('', 'value')
      )(properties),
      [TNG_USER]: flow(
        find({ name: TNG_USER }),
        getOr('', 'value')
      )(properties),
      [TWILIO_ENABLED]: flow(
        find({ name: TWILIO_ENABLED }),
        getOr('false', 'value'),
        (value) => value === 'true'
      )(properties),
      [USER_TEST_DATA_ACCESS]: getTestDataAccessPerm(accessParameters)
    })
  )(defaultValues);
