import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { withPermissions } from '@retail/backoffice-ui/src/Permission/withPermissions';
import { useSearchParamsController } from '@retail/hooks';
import { Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSearchRolesQuery } from '~/apollo/gql-types';
import { PaginationRow } from '~/components/PaginationRow';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { useRolesOverviewColumns } from './hooks/useRolesOverviewColumns';
import { SearchForm } from './SearchForm';
import cn from './styles.less';
import {
  getRolesOverviewParams,
  ROLES_OVERVIEW_BASE_OPTIONS,
  RolesOverviewParamsModel
} from './utils';

export const RolesOverview = withPermissions({
  allow: PERMISSIONS.QUERY_SEARCH_ROLES,
  domain: DOMAINS.ROLE_USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const columns = useRolesOverviewColumns();
  const getHref = useHrefCallback();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<RolesOverviewParamsModel>(
      ({ queryString, navigate }) =>
        navigate(`${getHref('ROLES')}${queryString}`)
    );

  const { data, loading: isLoading } = useSearchRolesQuery({
    ...ROLES_OVERVIEW_BASE_OPTIONS,
    skip: !controller.name,
    variables: {
      search: getRolesOverviewParams(controller)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });
  const dataSource = data?.data?.entities ?? [];

  return (
    <Layout>
      <LayoutHeader title={t('bo.admin.rolesOverview.title')} />

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            name={controller.name}
            isLoading={isLoading}
            onSubmit={updateController}
          />
          {controller.name ? (
            <>
              <PaginationRow
                showTotalResults
                isLoading={isLoading}
                onChange={onPageChange}
                current={controller.page}
                total={controller.totalPages}
                pageSize={controller.pageSize}
              />
              <Table
                rowKey="id"
                dataSource={dataSource}
                columns={columns}
                loading={isLoading}
                pagination={false}
              />
              <PaginationRow
                isLoading={isLoading}
                onChange={onPageChange}
                current={controller.page}
                total={controller.totalPages}
                pageSize={controller.pageSize}
              />
            </>
          ) : null}
        </Space>
      </LayoutBody>
    </Layout>
  );
});
