import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { withPermissions } from '@retail/backoffice-ui/src/Permission/withPermissions';
import { useSearchParamsController } from '@retail/hooks';
import { Button, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSearchGroupsQuery } from '~/apollo/gql-types';
import { PaginationRow } from '~/components/PaginationRow';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useHref } from '~/hooks/useHref';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { useGroupsOverviewColumns } from './hooks/useGroupsOverviewColumns';
import { SearchForm } from './SearchForm';
import cn from './styles.less';
import {
  getGroupsOverviewParams,
  GROUPS_OVERVIEW_BASE_OPTIONS,
  GroupsOverviewParamsModel
} from './utils';

export const GroupsOverview = withPermissions({
  allow: PERMISSIONS.QUERY_SEARCH_GROUPS,
  domain: DOMAINS.GROUP_USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const href = useHref('GROUP_CREATION');
  const columns = useGroupsOverviewColumns();
  const getHref = useHrefCallback();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<GroupsOverviewParamsModel>(
      ({ queryString, navigate }) =>
        navigate(`${getHref('GROUPS')}${queryString}`)
    );

  const { data, loading: isLoading } = useSearchGroupsQuery({
    ...GROUPS_OVERVIEW_BASE_OPTIONS,
    variables: {
      search: getGroupsOverviewParams(controller)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });
  const dataSource = data?.data?.entities ?? [];

  return (
    <Layout>
      <LayoutHeader title={t('bo.admin.groupsOverview.title')}>
        <PermissionChecker
          domain={DOMAINS.GROUP_USER_MANAGEMENT}
          allow={PERMISSIONS.MUTATION_CREATE_GROUP}
        >
          <Button type="primary" href={href}>
            {t('bo.admin.groupsOverview.buttons.createGroup')}
          </Button>
        </PermissionChecker>
      </LayoutHeader>

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            name={controller.name}
            isLoading={isLoading}
            onSubmit={updateController}
          />
          <PaginationRow
            showTotalResults
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
          />
          <PaginationRow
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
