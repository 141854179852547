import { Card } from 'antd';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupProjection } from '~/apollo/gql-types';
import {
  AntOptionProps,
  DEFAULT_SELECT_ALL_OPTION,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { RoleProjectionFormModel } from '../models';

const FIELD_NAME = 'groupIds';

interface Props {
  control: Control<RoleProjectionFormModel>;
  groups: GroupProjection[];
  loading: boolean;
}

export function AssignGroupsCard({ control, groups, loading }: Props) {
  const { t } = useTranslation();
  const getHref = useHrefCallback();

  const options = useMemo(
    () =>
      groups?.map(
        ({ name: label, id: value }): AntOptionProps => ({
          label,
          value
        })
      ) ?? [],
    [groups]
  );

  return (
    <Card
      data-qa-selector-name="assignGroupsSection"
      title={t('bo.admin.roleItem.form.assignGroups.title')}
      loading={loading}
    >
      <SelectControlled
        qaSelector="groupIds"
        options={options}
        required
        allowClear
        allowSelectAll
        selectAllOptionProps={{
          ...DEFAULT_SELECT_ALL_OPTION,
          label: t('bo.admin.select.selectAll')
        }}
        mode="multiple"
        controllerProps={{
          name: FIELD_NAME,
          control
        }}
        tagRender={(props) =>
          Tag({
            ...props,
            url: getHref('GROUP', { id: props.value })
          })
        }
      />
    </Card>
  );
}
