import { CopyOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Card, Col, notification, Row, Space } from 'antd';
import { sortBy } from 'lodash/fp';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AntOptionProps,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { useGroupsCopyPaste } from './useGroupsCopyPaste';

interface Props {
  options: Array<AntOptionProps>;
  loading: boolean;
}

const GROUP_IDS_FIELD = 'groupIds';

export function AssignGroups({ options, loading }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const getHref = useHrefCallback();

  const sortValues = useMemo(() => {
    const optionValuesInOrder = options.map((it) => it.value);

    return (values: string[]) =>
      sortBy((value) => optionValuesInOrder.indexOf(value), values);
  }, [options]);

  const groupsCopyPaste = useGroupsCopyPaste({
    groupOptions: options,
    groupIdsField: GROUP_IDS_FIELD
  });

  const handleCopy = async () => {
    try {
      await groupsCopyPaste.copy();
      notification.success({
        message: t('bo.admin.usersForm.assignGroups.groupsCopy.success')
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('bo.admin.usersForm.assignGroups.groupsCopy.error')
      });
    }
  };

  const handlePaste = async () => {
    const result = await groupsCopyPaste.paste();

    if (result.isSuccess) {
      notification.success({
        message: t('bo.admin.usersForm.assignGroups.groupsPaste.successStats', {
          added: result.addedGroups,
          total: result.totalGroups
        })
      });
    } else {
      notification.error({
        message: result.isPermissionError
          ? t('bo.admin.usersForm.assignGroups.groupsPaste.errorPermissions')
          : t('bo.admin.usersForm.assignGroups.groupsPaste.errorGeneral')
      });
    }
  };

  return (
    <Card
      title={t('bo.admin.usersForm.assignGroups.title')}
      loading={loading}
      extra={
        <Space>
          <Button icon={<CopyOutlined />} onClick={handleCopy}>
            {t('bo.admin.usersForm.assignGroups.copyGroupsButton.text')}
          </Button>
          <Button icon={<FileAddOutlined />} onClick={handlePaste}>
            {t('bo.admin.usersForm.assignGroups.pasteGroupsButton.text')}
          </Button>
        </Space>
      }
    >
      <Row gutter={24}>
        <Col sm={24}>
          <SelectControlled
            required
            allowClear
            allowSelectAll
            options={options}
            mode="multiple"
            qaSelector="user-groupIds"
            tagRender={(props) =>
              Tag({
                ...props,
                url: getHref('GROUP', { id: props.value })
              })
            }
            controllerProps={{
              control,
              name: GROUP_IDS_FIELD
            }}
            sortValues={sortValues}
          />
        </Col>
      </Row>
    </Card>
  );
}
