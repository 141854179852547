import { filter, flow, get, includes, map } from 'lodash/fp';

import { UserAccessParameterValue } from '~/constants/auxUser/accessParams';

export const hasAccess = (value, userAccess): boolean => {
  if (includes(UserAccessParameterValue.ALL, userAccess)) {
    return true;
  }

  if (includes(UserAccessParameterValue.NONE, userAccess)) {
    return false;
  }

  return includes(value, userAccess) as unknown as boolean; //Todo: check typing for better solution
};

export const getUserAccess = (key) => {
  return flow(filter({ key }), map(get(['value'])));
};
