/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ColProps, Select, SelectProps } from 'antd';
import { OptionProps } from 'antd/es/select';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { FIELDS_LABEL_COL_SPAN } from '~/constants/form';

import { FormItem } from './FormItem';

export interface AntOptionProps
  extends Partial<OptionProps>,
    Pick<Required<OptionProps>, 'value'> {
  label: ReactNode;
}

const { Option } = Select;

const defaultGetOptionValue = ({ value }: AntOptionProps) => value;
const defaultGetOptionLabel = ({ label }: AntOptionProps) => label;
const defaultFilterOption = (input, option) =>
  option.children.toLowerCase().includes(input.toLowerCase());
const getOnChangeValue = (value) => value ?? null;

export interface ISelectControlledProps<T>
  extends Omit<SelectProps, 'options' | 'mode'> {
  controllerProps: UseControllerProps<T>;
  labelCol?: ColProps;
  label?: ReactNode;
  options: Array<AntOptionProps>;
  required?: boolean;
  qaSelector?: string;
  getOptionValue?: (item: AntOptionProps) => string;
  getOptionLabel?: (item: AntOptionProps) => ReactNode;
  sortValues?: (values: string[]) => string[];
  allowSelectAll?: boolean;
  selectAllOptionProps?: AntOptionProps;
  showArrow?: boolean;
}

type TSelectConditionalProps =
  | {
      mode: SelectProps['mode'];
      allowSelectAll?: boolean;
      selectAllOptionProps?: AntOptionProps;
    }
  | {
      allowSelectAll?: never;
      selectAllOptionProps?: never;
    };

export type ISelectProps<T> = ISelectControlledProps<T> &
  TSelectConditionalProps;

export const DEFAULT_SELECT_ALL_OPTION = {
  value: '*All*',
  label: 'Select all'
};

export function SelectControlled<T extends FieldValues>({
  label,
  options: defaultOptions,
  labelCol,
  controllerProps,
  filterOption = defaultFilterOption,
  required,
  disabled,
  sortValues,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  onChange,
  className,
  qaSelector,
  allowSelectAll,
  showArrow = true,
  selectAllOptionProps = DEFAULT_SELECT_ALL_OPTION,
  ...attr
}: ISelectProps<T>) {
  const { field } = useController(controllerProps);
  const options = useMemo(() => {
    if (!allowSelectAll) {
      return defaultOptions;
    }

    return [
      {
        ...selectAllOptionProps,
        label: `${selectAllOptionProps.label} (${defaultOptions?.length})`
      },
      ...defaultOptions
    ];
  }, [allowSelectAll, defaultOptions, selectAllOptionProps]);

  const handleChange = useCallback(
    (value, option) => {
      let newValue = value;

      if (allowSelectAll && value.includes(selectAllOptionProps?.value)) {
        newValue = options
          .map(({ value }) => value)
          .filter((value) => value !== selectAllOptionProps?.value);
      }

      field.onChange(getOnChangeValue(newValue));
      onChange?.(getOnChangeValue(newValue), option);
    },
    [field, onChange, options, allowSelectAll, selectAllOptionProps]
  );

  const value = useMemo(
    () =>
      sortValues && Array.isArray(field.value)
        ? sortValues(field.value)
        : field.value,
    [sortValues, field.value]
  );

  return (
    <FormItem
      label={label}
      labelCol={labelCol || { span: FIELDS_LABEL_COL_SPAN }}
      required={required}
      disabled={disabled}
      className={className}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <Select
        {...field}
        disabled={disabled}
        filterOption={filterOption}
        value={value}
        data-qa-selector-name={qaSelector}
        dropdownClassName={`${qaSelector}-dropdown`}
        onChange={handleChange}
        showArrow={showArrow}
        {...attr}
      >
        {options?.map((item) => (
          <Option
            {...item}
            key={getOptionValue(item)!}
            value={getOptionValue(item)}
            data-qa-selector="option"
            data-qa-selector-option-value={getOptionValue(item)}
            data-qa-selector-option-label={getOptionLabel(item)}
          >
            {getOptionLabel(item)}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
}
