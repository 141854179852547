import { omit, values } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useMethods, useMountedState, usePrevious } from 'react-use';

import {
  FORM_FIELD_ASSIGN_USERS,
  FORM_FIELD_UNASSIGN_USERS
} from '../../constants';
import { useSearchUsers } from '../../hooks/useSearchUsers';
import { ASSIGNED, createMethods, filterUsersBySearchText } from '../utils';

import { useInitialState } from './useInitialState';

export function useAssignUsersProps() {
  const initialState = useInitialState();
  const {
    control,
    formState: { isDirty }
  } = useFormContext();

  const {
    field: { onChange: onAssignUsersChange }
  } = useController({
    name: FORM_FIELD_ASSIGN_USERS,
    control
  });
  const {
    field: { onChange: onUnassignUsersChange }
  } = useController({
    name: FORM_FIELD_UNASSIGN_USERS,
    control
  });
  const [state, methods] = useMethods(createMethods, initialState);
  const isMounted = useMountedState();

  const prevIsFormPristine = usePrevious(!isDirty);
  const { searchAssignedUsers, searchUnassignedUsers } = useSearchUsers();

  const [newAssignedUsers = [], newUnassignedUsers = []] = useWatch({
    control,
    name: [FORM_FIELD_ASSIGN_USERS, FORM_FIELD_UNASSIGN_USERS]
  });

  const assignedLoaderRef = useRef(null);
  const unassignedLoaderRef = useRef(null);
  const isDraggableItemsTouchedRef = useRef(false);

  const assignedColumnPage = state.columns.assigned.page;
  const loadAssignedUsers = useCallback(
    async ({ searchText, ...payload }) => {
      methods.setAssignedUsersLoading(true);

      const result = await searchAssignedUsers({
        page: assignedColumnPage + 1,
        search: searchText,
        ...payload
      });

      // filter out removed items on new search load
      result.entities = result.entities.filter(
        ({ id }) => !newUnassignedUsers[id]
      );

      if (!isMounted()) {
        return;
      }

      if (payload.page === 0) {
        let formValues = values(newAssignedUsers);

        if (searchText) {
          formValues = filterUsersBySearchText(searchText, formValues);
        }

        // keep new items in the list on new search load
        if (formValues.length > 0) {
          result.entities = [...formValues, ...result.entities];
        }

        // reset loader cache on new load
        if (assignedLoaderRef.current) {
          assignedLoaderRef.current.resetloadMoreItemsCache();
        }

        methods.setAssignedUsers(result);
      } else {
        methods.pushAssignedUsers(result);
      }
    },
    [
      methods,
      searchAssignedUsers,
      assignedColumnPage,
      isMounted,
      newUnassignedUsers,
      newAssignedUsers
    ]
  );

  const unassignedColumnPage = state.columns.unassigned.page;
  const loadUnassignedUsers = useCallback(
    async ({ searchText, ...payload }) => {
      methods.setUnassignedUsersLoading(true);

      const result = await searchUnassignedUsers({
        page: unassignedColumnPage + 1,
        search: searchText,
        ...payload
      });

      // filter out removed items on new search load
      result.entities = result.entities.filter(
        ({ id }) => !newAssignedUsers[id]
      );

      if (!isMounted()) {
        return;
      }

      if (payload.page === 0) {
        let formValues = values(newUnassignedUsers);

        if (searchText) {
          formValues = filterUsersBySearchText(searchText, formValues);
        }

        // keep new items in the list on new search load
        if (formValues.length > 0) {
          result.entities = [...formValues, ...result.entities];
        }

        // reset loader cache on new load
        if (unassignedLoaderRef.current) {
          unassignedLoaderRef.current.resetloadMoreItemsCache();
        }

        methods.setUnassignedUsers(result);
      } else {
        methods.pushUnassignedUsers(result);
      }
    },
    [
      methods,
      searchUnassignedUsers,
      unassignedColumnPage,
      isMounted,
      newAssignedUsers,
      newUnassignedUsers
    ]
  );

  // reload data on undo changes and form submission
  useEffect(() => {
    const reloadData = async () => {
      await loadAssignedUsers({ page: 0, searchText: '' });
      await loadUnassignedUsers({ page: 0, searchText: '' });
    };
    if (
      isDraggableItemsTouchedRef.current === true &&
      prevIsFormPristine === false &&
      !isDirty
    ) {
      reloadData();

      isDraggableItemsTouchedRef.current = false;
    }
  }, [
    isDirty,
    prevIsFormPristine,
    loadAssignedUsers,
    loadUnassignedUsers,
    isDraggableItemsTouchedRef
  ]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      // forbid reordering in the same list
      if (result.source.droppableId === result.destination.droppableId) {
        return;
      }

      isDraggableItemsTouchedRef.current = true;

      const formFields = {
        [state.columns.assigned.field]: newAssignedUsers,
        [state.columns.unassigned.field]: newUnassignedUsers
      };
      // moving between lists
      const sourceColumn = state.columns[result.source.droppableId];
      const destinationColumn = state.columns[result.destination.droppableId];
      const item = sourceColumn.items[result.source.index];

      item.isNew = !item.isNew;

      // 1. remove item from source column
      const newSourceColumn = {
        ...sourceColumn,
        items: sourceColumn.items.filter((_, k) => k !== result.source.index)
      };

      // 2. insert into destination column
      const newDestinationColumn = {
        ...destinationColumn,
        items: [...destinationColumn.items]
      };

      newDestinationColumn.items.splice(result.destination.index, 0, item);

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newSourceColumn.id]: newSourceColumn,
          [newDestinationColumn.id]: newDestinationColumn
        }
      };

      const setNewValue =
        destinationColumn.id === ASSIGNED
          ? onAssignUsersChange
          : onUnassignUsersChange;

      // update form fields
      if (item.isNew) {
        setNewValue({
          ...formFields[destinationColumn.field],
          [item.id]: item
        });
      } else {
        setNewValue(omit([item.id], formFields[sourceColumn.field]));
      }

      // update local state
      methods.replace(newState);
    },
    [
      methods,
      newAssignedUsers,
      newUnassignedUsers,
      onAssignUsersChange,
      onUnassignUsersChange,
      state
    ]
  );

  return useMemo(
    () => ({
      state,
      loadAssignedUsers,
      assignedLoaderRef,
      loadUnassignedUsers,
      unassignedLoaderRef,
      onDragEnd
    }),
    [loadAssignedUsers, loadUnassignedUsers, onDragEnd, state]
  );
}
