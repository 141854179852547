import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { COUNTRIES_VALUES } from '@retail/i18n/constants';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserAccessParameter } from '~/constants/auxUser/accessParams';
import { IUserContext } from '~/providers/UserProvider';

function sorter(a: DefaultOptionType, b: DefaultOptionType) {
  return a.label?.toString?.().localeCompare(b.label?.toString?.());
}

export function useAccessibleCountryOptions() {
  const { accessParameters } = useUserContext<IUserContext>();
  const { t } = useTranslation();

  return useMemo(() => {
    if (
      accessParameters.some(
        ({ key, value }) => key === UserAccessParameter.COUNTRY && value === '*'
      )
    ) {
      return COUNTRIES_VALUES.map(
        (value: string): DefaultOptionType => ({
          value,
          label: t(`bo.common.country.${value}`)
        })
      ).sort(sorter);
    }

    return accessParameters
      .filter(
        ({ key, value }) =>
          key === UserAccessParameter.COUNTRY &&
          COUNTRIES_VALUES.includes(value)
      )
      .map(
        ({ value }): DefaultOptionType => ({
          value,
          label: t(`bo.common.country.${value}`)
        })
      )
      .sort(sorter);
  }, [accessParameters, t]);
}
