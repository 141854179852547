import { formatDateTime } from 'helpers/date';
import getCountryAccessLabel from 'helpers/fieldOptions/admin/users/countryAccess/getLabel';
import { formatUser } from 'helpers/user';
import { filter, flow, get, join, map } from 'lodash/fp';
import { useMemo } from 'react';

import { UserAccessParameter } from '~/constants/auxUser/accessParams';
import { SearchUserModel } from '~/types/SearchUserModel';

export const useFormattedOverviewData = (entities: SearchUserModel[]) =>
  useMemo(
    () =>
      map((entity: SearchUserModel) => ({
        ...entity,
        countryAccess: flow(
          filter({ key: UserAccessParameter.COUNTRY }),
          map(get(['value'])),
          map(getCountryAccessLabel),
          join(', ')
        )(entity.accessParameters),
        name: formatUser(entity),
        loginTime: entity.loginTime && formatDateTime(entity.loginTime),
        createdOn: formatDateTime(entity.createdOn)
      }))(entities),
    [entities]
  );
