import { useFormContext } from 'react-hook-form';

import { AntOptionProps } from '~/components/Form/SelectControlled';

interface GroupsCopyPasteParams {
  groupOptions: AntOptionProps[];
  groupIdsField: string;
}

const parseGroupIds = (text: string): string[] | null => {
  try {
    const data = JSON.parse(text);

    return data &&
      typeof data === 'object' &&
      'groupIds' in data &&
      Array.isArray(data.groupIds)
      ? data.groupIds
      : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useGroupsCopyPaste = ({
  groupOptions,
  groupIdsField
}: GroupsCopyPasteParams) => {
  const { getValues, setValue } = useFormContext();

  const copy = async () => {
    const groupIds = getValues(groupIdsField) as string[];

    await window.navigator.clipboard.writeText(
      JSON.stringify({
        groupIds
      })
    );
  };

  const paste = async () => {
    try {
      const groupIdsToPaste = await window.navigator.clipboard
        .readText()
        .then(parseGroupIds);

      if (groupIdsToPaste) {
        const currentGroupIds = getValues(groupIdsField);
        const groupIdsToAdd = groupIdsToPaste.filter((id) => {
          if (currentGroupIds.includes(id)) {
            return false;
          }

          const option = groupOptions.find((it) => it.value === id);

          return option != null && !option.disabled;
        });

        setValue(groupIdsField, [...currentGroupIds, ...groupIdsToAdd], {
          shouldDirty: true
        });

        return {
          isSuccess: true,
          addedGroups: groupIdsToAdd.length,
          totalGroups: groupIdsToPaste.length
        } as const;
      }

      return {
        isSuccess: false,
        isPermissionError: false
      } as const;
    } catch (e) {
      console.error(e);

      return {
        isSuccess: false,
        isPermissionError:
          e instanceof DOMException && ['NotAllowedError'].includes(e.name)
      } as const;
    }
  };

  return {
    copy,
    paste
  };
};
