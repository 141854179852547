import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useActive } from '~/hooks/useActive';
import { SearchUserModel } from '~/types/SearchUserModel';

import { UserTeamsModalForm } from './UserTeamsModalForm';

interface Props {
  data: SearchUserModel;
}

export function Actions({ data }: Props) {
  const { active, setActive, setInactive } = useActive();

  return (
    <div>
      <Button
        size="small"
        type="primary"
        data-qa="actionsButton"
        onClick={setActive}
        icon={<EditOutlined />}
      />
      <UserTeamsModalForm
        active={active}
        onCancel={setInactive}
        initialValues={data}
      />
    </div>
  );
}
