import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldCol } from './FieldCol';
import { useAccessibleCountryOptions } from './hooks/useAccessibleCountryOptions';
import { useSearchDepartmentsAndTeamsOptions } from './hooks/useSearchDepartmentsAndTeamsOptions';

interface Props {
  isLoading: boolean;
  onReset: VoidFunction;
}

export function SearchFields({ isLoading, onReset }: Props) {
  const { t } = useTranslation();
  const {
    options,
    unassignedOptions,
    isLoading: isOptionsLoading
  } = useSearchDepartmentsAndTeamsOptions();
  const countryOptions = useAccessibleCountryOptions();
  const form = Form.useFormInstance();
  const department = Form.useWatch('department');
  const teamOptions = useMemo(
    () =>
      options.find((x) => x.value === department)?.children ??
      unassignedOptions,
    [department, options, unassignedOptions]
  );

  function onClear() {
    form.setFieldsValue({ team: null });
  }

  return (
    <Card>
      <Row gutter={16} align="bottom">
        <FieldCol>
          <Form.Item
            name="department"
            label={t('bo.admin.userTeams.table.department')}
          >
            <Select
              allowClear
              options={options}
              loading={isOptionsLoading}
              data-qa="department"
              placeholder={t('bo.common.field.placeholder.selectValue')}
              onSelect={onClear}
              onClear={onClear}
            />
          </Form.Item>
        </FieldCol>
        <FieldCol>
          <Form.Item name="team" label={t('bo.admin.userTeams.table.team')}>
            <Select
              allowClear
              loading={isOptionsLoading}
              options={teamOptions}
              data-qa="team"
              placeholder={t('bo.common.field.placeholder.selectValue')}
            />
          </Form.Item>
        </FieldCol>
        <FieldCol>
          <Form.Item
            name="country"
            label={t('bo.admin.userTeams.table.country')}
          >
            <Select
              allowClear
              options={countryOptions}
              data-qa="country"
              placeholder={t('bo.common.field.placeholder.selectValue')}
            />
          </Form.Item>
        </FieldCol>
        <FieldCol>
          <Form.Item name="text" label={t('bo.admin.userTeams.form.search')}>
            <Input
              autoFocus
              data-qa="search"
              placeholder={t('bo.admin.userTeams.form.search.placeholder')}
            />
          </Form.Item>
        </FieldCol>
        <Col flex="auto">
          <Row justify="end" align="bottom">
            <Col span="auto">
              <Row gutter={16}>
                <Col>
                  <Button
                    data-qa="reset"
                    loading={isLoading}
                    icon={<ClearOutlined />}
                    onClick={onReset}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-qa="submit"
                    loading={isLoading}
                    icon={<SearchOutlined />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
