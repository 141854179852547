import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { withPermissions } from '@retail/backoffice-ui/src/Permission/withPermissions';
import { LinkButton } from '@retail/backoffice-urls';
import { createFilterPayload, Sorts } from '@retail/gql-utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRoleInfoQuery } from '~/apollo/gql-types';
import { WideSpace } from '~/components/WideSpace';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { RoleItemForm } from './RoleItemForm';
import { RoleUsers } from './RoleUsers';
import cn from './styles.less';
import { ROLE_ITEM_BASE_OPTIONS } from './utils';

const GROUPS_SEARCH = createFilterPayload({ sorts: [Sorts.ask('name')] });

export const RoleItem = withPermissions({
  allow: PERMISSIONS.QUERY_ROLE,
  domain: DOMAINS.ROLE_USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const { id: roleId } = useParams();
  const getHref = useHrefCallback();

  const { data, loading } = useGetRoleInfoQuery({
    ...ROLE_ITEM_BASE_OPTIONS,
    variables: { roleId, groupsSearch: GROUPS_SEARCH }
  });

  const title = `${t('bo.admin.roleItem.edit.title')} ${
    data?.role?.name || ''
  }`;

  return (
    <Layout className={cn.wrapper}>
      <LayoutHeader title={title}>
        <LinkButton type="link" to={getHref('ROLES')}>
          {t('bo.admin.buttons.goToList')}
        </LinkButton>
      </LayoutHeader>
      <LayoutBody>
        <WideSpace direction="vertical" size="large">
          <RoleItemForm
            item={data?.role}
            groups={data?.groups?.entities}
            loading={loading}
          />

          <RoleUsers items={data?.users?.entities} loading={loading} />
        </WideSpace>
      </LayoutBody>
    </Layout>
  );
});
