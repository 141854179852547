import { TableCell } from '@retail/backoffice-ui/src/PaginatedTable/TableCell';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { SortType } from '@retail/gql-utils';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { SearchUserModel } from '~/types/SearchUserModel';
import { isNotNil } from '~/utils/boolean';

import { Actions } from '../Actions';
import { UserTeamsSearchModel } from '../types';

import { SortOrder } from './useDataSource';

interface Props {
  controller: UserTeamsSearchModel;
}

type Column = TableColumnType<SearchUserModel>;

export function useColumns({ controller: { sort, direction } }: Props) {
  const { t } = useTranslation();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_UPDATE_USER_TEAM,
    domain: DOMAINS.USER_MANAGEMENT
  });

  return useMemo(
    (): Column[] =>
      Array.from<Column>([
        {
          sorter: true,
          dataIndex: 'firstName',
          title: t('bo.admin.userTeams.table.firstName'),
          render: (value) => <TableCell data-qa="firstName">{value}</TableCell>
        },
        {
          sorter: true,
          dataIndex: 'lastName',
          title: t('bo.admin.userTeams.table.lastName'),
          render: (value) => <TableCell data-qa="lastName">{value}</TableCell>
        },
        {
          sorter: true,
          dataIndex: 'email',
          title: t('bo.admin.userTeams.table.email'),
          render: (value) => <TableCell data-qa="email">{value}</TableCell>
        },
        {
          sorter: true,
          dataIndex: 'department',
          title: t('bo.admin.userTeams.table.department'),
          render: (value) => (
            <TableCell data-qa="department">
              {value
                ? t(`bo.admin.userTeams.option.department.${value}`)
                : null}
            </TableCell>
          )
        },
        {
          sorter: true,
          dataIndex: 'team',
          title: t('bo.admin.userTeams.table.team'),
          render: (value) => (
            <TableCell data-qa="team">
              {value ? t(`bo.admin.userTeams.option.team.${value}`) : value}
            </TableCell>
          )
        },
        {
          dataIndex: 'country',
          title: t('bo.admin.userTeams.table.country'),
          render: (value) => (
            <TableCell data-qa="country">
              {value ? t(`bo.common.country.${value}`) : null}
            </TableCell>
          )
        },
        isAllowed
          ? {
              title: t('bo.admin.userTeams.table.actions'),
              align: 'center',
              width: 65,
              render: (_, data: SearchUserModel) => (
                <TableCell data-qa="actions">
                  <Actions data={data} />
                </TableCell>
              )
            }
          : null
      ])
        .filter(isNotNil)
        .map((column) => {
          const sortOrder =
            direction === SortType.ASC ? SortOrder.Ascend : SortOrder.Descend;
          const defaultSortOrder = column.dataIndex === sort ? sortOrder : null;

          return {
            ...column,
            defaultSortOrder
          };
        }),
    [direction, isAllowed, sort, t]
  );
}
