import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { PaginatedTable } from '@retail/backoffice-ui/src/PaginatedTable';
import {
  useCheckPermissions,
  withPermissions
} from '@retail/backoffice-ui/src/Permission';
import { useSyncRef } from '@retail/hooks/src/useSyncRef';
import { Form, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { useColumns } from './hooks/useColumns';
import { DEFAULT_VALUES, useDataSource } from './hooks/useDataSource';
import { SearchFields } from './SearchFields';
import { UserTeamsSearchModel } from './types';

export const UsersTeams = withPermissions({
  allow: [
    PERMISSIONS.QUERY_USER_DEPARTMENTS_AND_TEAMS,
    PERMISSIONS.MUTATION_UPDATE_USER_TEAM
  ],
  domain: DOMAINS.USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const {
    controller,
    dataSource,
    isLoading,
    onPageChange,
    updateController,
    clearController,
    onChange
  } = useDataSource();
  const [form] = Form.useForm<UserTeamsSearchModel>();
  const columns = useColumns({ controller });
  const ref = useSyncRef(controller);

  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.QUERY_USER_DEPARTMENTS_AND_TEAMS,
    domain: DOMAINS.USER_MANAGEMENT
  });

  useEffect(() => {
    form.setFieldsValue(ref.current);
  }, [form, ref]);

  function onSubmit(values: UserTeamsSearchModel) {
    updateController(values);
  }

  function onReset() {
    clearController();
    form.resetFields();
    onSubmit(DEFAULT_VALUES);
  }

  return (
    <Layout>
      <LayoutHeader title={t('bo.admin.userTeams.title')} />
      <LayoutBody>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {isAllowed ? (
            <Form
              form={form}
              data-qa="searchForm"
              initialValues={DEFAULT_VALUES}
              onFinish={onSubmit}
              disabled={isLoading}
              labelCol={{ span: 24 }}
            >
              <SearchFields isLoading={isLoading} onReset={onReset} />
            </Form>
          ) : null}
          <PaginatedTable
            size="small"
            dataSource={dataSource}
            columns={columns}
            isLoading={isLoading}
            controller={controller}
            onChange={onChange}
            onPageChange={onPageChange}
            onRow={({ email }) => ({ 'data-qa': email })}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
