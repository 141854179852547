const ROOT = `/:language/admin` as const;

const PURE_PATHS = {
  ROOT,
  USERS: `/users`,
  USER: `/users/:id`,
  USER_CREATION: `/users/new`,
  ROLES: `/roles`,
  ROLE: `/roles/:id`,
  GROUPS: `/groups`,
  GROUP: `/groups/:id`,
  GROUP_CREATION: `/groups/new`,
  USER_TEAMS: `/user-teams`
} as const;

export type PurePathRecord = typeof PURE_PATHS;
export type PathName = keyof PurePathRecord;
export type PathRecord = {
  [P in PathName]: PurePathRecord[P] extends typeof ROOT
    ? PurePathRecord[P]
    : `/:language/admin${PurePathRecord[P]}`;
};

export const PATHS = Object.fromEntries(
  Object.entries(PURE_PATHS).map(
    ([key, value]) => [key, `${value === ROOT ? '' : ROOT}${value}`] as const
  )
) as PathRecord;
