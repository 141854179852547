import {
  createFilter,
  createFilterPayload,
  FilterType,
  SortType
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { DOMAINS } from '~/constants/permissions';

import { RolesOverviewModel } from './SearchForm';

export const ROLES_OVERVIEW_BASE_OPTIONS = {
  context: {
    headers: { 'access-domain': DOMAINS.ROLE_USER_MANAGEMENT }
  }
};

export const DEFAULT_SORTING = {
  direction: SortType.ASC,
  property: 'name'
};
const SORTS = [DEFAULT_SORTING];

export interface RolesOverviewParamsModel
  extends Partial<RolesOverviewModel>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export function getRolesOverviewParams({
  page: rawPage,
  pageSize,
  name
}: RolesOverviewParamsModel) {
  const page = Number(rawPage) - 1 || 0;
  const filters = [];

  if (name?.length > 0) {
    filters.push(createFilter('name', FilterType.LIKE, name));
  }

  let filter = null;

  if (filters.length) {
    filter =
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : filters[0];
  }

  return createFilterPayload({
    filter,
    sorts: SORTS,
    page,
    pageSize
  });
}
