import { PathRouteProps } from 'react-router-dom';

import { PATHS } from './constants/route';
import { GroupForm } from './pages/GroupForm';
import { GroupsOverview } from './pages/GroupsOverview';
import { RoleItem } from './pages/RoleItem';
import { RolesOverview } from './pages/RolesOverview';
import { UserCreation } from './pages/UserCreation';
import { UserEditing } from './pages/UserEditing';
import { UsersOverview } from './pages/UsersOverview';
import { UsersTeams } from './pages/UserTeams';

export const ROUTES: PathRouteProps[] = [
  {
    path: PATHS.USERS,
    element: <UsersOverview />
  },
  {
    path: PATHS.USER,
    element: <UserEditing />
  },
  {
    path: PATHS.USER_CREATION,
    element: <UserCreation />
  },
  {
    path: PATHS.ROLES,
    element: <RolesOverview />
  },
  {
    path: PATHS.ROLE,
    element: <RoleItem />
  },
  {
    path: PATHS.GROUPS,
    element: <GroupsOverview />
  },
  {
    path: PATHS.GROUP,
    element: <GroupForm />
  },
  {
    path: PATHS.GROUP_CREATION,
    element: <GroupForm />
  },
  {
    path: PATHS.USER_TEAMS,
    element: <UsersTeams />
  }
];
