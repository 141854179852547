import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserDepartmentsAndTeamsQuery } from '~/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { USER_ACCESS_DOMAIN } from '~/constants/user';

export function useDepartmentsAndTeamsOptions() {
  const { t } = useTranslation();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.QUERY_USER_DEPARTMENTS_AND_TEAMS,
    domain: DOMAINS.USER_MANAGEMENT
  });
  const { data, loading } = useUserDepartmentsAndTeamsQuery({
    ...USER_ACCESS_DOMAIN,
    skip: !isAllowed,
    fetchPolicy: 'cache-first'
  });

  const options = useMemo(() => {
    const { departments = [] } = data?.userDepartmentsAndTeams ?? {};

    return departments.map(
      ({ department, teams }): DefaultOptionType => ({
        label: t(`bo.admin.userTeams.option.department.${department}`),
        value: department,
        children: teams.map(
          (team): DefaultOptionType => ({
            label: t(`bo.admin.userTeams.option.team.${team}`),
            value: team
          })
        )
      })
    );
  }, [data?.userDepartmentsAndTeams, t]);

  return { options, isLoading: loading };
}
