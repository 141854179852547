import {
  createFilter,
  createFilterPayload,
  createSort,
  Filter,
  FilterType,
  Sort,
  SortType
} from '@retail/gql-utils';
import { ControllerModel } from '@retail/hooks';

import {
  UserAccessParameter,
  UserAccessParameterValue
} from '~/constants/auxUser/accessParams';
import { REGISTERED_FROM_BACKOFFICE_AND_ADMIN_FILTER } from '~/constants/common';

export interface SearchUsersVariablesProps extends ControllerModel {
  sort?: string;
  direction?: SortType;
  defaultSorts?: Sort[];
  text?: string;
  countryAccess?: string[];
  enabled?: boolean;
  team?: string;
  department?: string;
  country?: string;
}

function createFilterIsNull(field: string, type: FilterType, value: unknown) {
  return value === FilterType.IS_NULL
    ? createFilter(field, FilterType.IS_NULL, null)
    : createFilter(field, type, value);
}

export function getSearchUsersVariables({
  sort,
  direction,
  page: rawPage,
  defaultSorts,
  pageSize,
  text,
  countryAccess,
  enabled,
  team,
  department,
  country
}: SearchUsersVariablesProps) {
  const page = Number(rawPage) - 1 || 0;

  const filters: Filter[] = [REGISTERED_FROM_BACKOFFICE_AND_ADMIN_FILTER];

  if (text) {
    filters.push(
      createFilter(null, FilterType.OR, [
        createFilter('firstName', FilterType.LIKE, String(text)),
        createFilter('lastName', FilterType.LIKE, String(text)),
        createFilter('email', FilterType.LIKE, String(text))
      ])
    );
  }

  const countryAccessAsArray = [countryAccess].flat().filter(Boolean);

  if (countryAccessAsArray?.length) {
    if (countryAccessAsArray.includes(UserAccessParameterValue.NONE)) {
      filters.push(
        createFilter(null, FilterType.OR, [
          createFilter(null, FilterType.AND, [
            createFilter(
              'accessParams.key',
              FilterType.EQUAL,
              UserAccessParameter.COUNTRY
            ),
            createFilter(
              'accessParams.value',
              FilterType.IN,
              countryAccessAsArray
            )
          ]),
          createFilter('accessParams.key', FilterType.IS_NULL, null)
        ])
      );
    } else {
      filters.push(
        createFilter(null, FilterType.AND, [
          createFilter(
            'accessParams.key',
            FilterType.EQUAL,
            UserAccessParameter.COUNTRY
          ),
          createFilter('accessParams.value', FilterType.IN, countryAccess)
        ])
      );
    }
  }

  if (typeof enabled === 'boolean') {
    filters.push(createFilter('enabled', FilterType.EQUAL, enabled));
  }

  if (team?.length) {
    filters.push(createFilterIsNull('team', FilterType.LIKE, team));
  }

  if (department?.length) {
    filters.push(createFilterIsNull('department', FilterType.LIKE, department));
  }

  if (country?.length) {
    filters.push(createFilter('country', FilterType.EQUAL, country));
  }

  const filter =
    filters.length > 1
      ? createFilter(null, FilterType.AND, filters)
      : filters[0];

  let sorts: Sort[];
  const sorting = Array.isArray(sort) ? sort?.join?.('.') : sort;

  if (sorting && direction) {
    sorts = [createSort(sorting, direction)];
  } else if (defaultSorts?.length > 0) {
    sorts = defaultSorts;
  }

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize
  });
}
