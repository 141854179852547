import { Form, Modal, Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateUserTeamMutation } from '~/apollo/gql-types';
import { useRequiredRules } from '~/hooks/useRequiredRules';
import { SearchUserModel } from '~/types/SearchUserModel';

import { useDepartmentsAndTeamsOptions } from '../hooks/useDepartmentsAndTeamsOptions';

export interface FormValues {
  id: string;
  department: string;
  team: string;
}

interface Props {
  active: boolean;
  initialValues: SearchUserModel;
  onCancel: VoidFunction;
}

export function UserTeamsModalForm({ active, initialValues, onCancel }: Props) {
  const { t } = useTranslation();
  const rules = useRequiredRules();
  const [update] = useUpdateUserTeamMutation();
  const { options, isLoading } = useDepartmentsAndTeamsOptions();
  const [form] = Form.useForm();
  const department = Form.useWatch('department', form);

  const teamOptions = useMemo(
    () => options.find((x) => x.value === department)?.children ?? [],
    [department, options]
  );

  useEffect(() => active && form.resetFields(), [active, form]);

  async function onFinish() {
    const { team, department } = await form.validateFields();
    const { id } = initialValues;

    await update({
      variables: {
        id,
        userTeam: { team, department }
      },
      refetchQueries: ['SearchUsers']
    });

    setTimeout(onCancel);
  }

  return (
    <Modal
      destroyOnClose
      visible={active}
      data-qa="userTeamsModal"
      onCancel={onCancel}
      onOk={onFinish}
      okButtonProps={{
        'data-qa': 'okButton'
      }}
      cancelButtonProps={{
        'data-qa': 'cancelButton'
      }}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        data-qa="userTeamsForm"
        onFinish={onFinish}
      >
        <Form.Item
          name="department"
          rules={rules}
          label={t('bo.admin.userTeams.table.department')}
        >
          <Select
            data-qa="department"
            loading={isLoading}
            placeholder={t('bo.common.field.placeholder.selectValue')}
            options={options}
            onSelect={() => {
              form.setFieldsValue({ team: null });
            }}
          />
        </Form.Item>
        <Form.Item
          name="team"
          rules={rules}
          label={t('bo.admin.userTeams.table.team')}
        >
          <Select
            data-qa="team"
            loading={isLoading}
            disabled={teamOptions.length === 0}
            placeholder={t('bo.common.field.placeholder.selectValue')}
            options={teamOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
