import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AntOptionProps } from '~/components/Form/SelectControlled';
import { UserAccessParameterValue } from '~/constants/auxUser/accessParams';
import countryOptions from '~/helpers/fieldOptions/country/options';

export const useCountryAccessOptions = (): Array<AntOptionProps> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: UserAccessParameterValue.ALL,
        label: t('bo.admin.options.all')
      },
      ...countryOptions,
      {
        value: UserAccessParameterValue.NONE,
        label: t('bo.admin.options.noCountry')
      }
    ],
    [t]
  ) as unknown as Array<AntOptionProps>;
};
