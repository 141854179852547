import { GroupProjection } from '~/apollo/gql-types';
import { Link } from '~/components/Link';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import cn from './styles.less';

interface Props {
  items: GroupProjection[];
}

export function Groups({ items }: Props) {
  const getHref = useHrefCallback();

  return (
    <div data-qa-selector-name="groups">
      {items.map(({ id, name }) => (
        <span
          key={id}
          data-qa-selector-name={`groups-item-${id}`}
          className={cn.group}
        >
          <Link to={getHref('GROUP', { id })}>{name}</Link>
        </span>
      ))}
    </div>
  );
}
