import { notification } from 'antd';
import { keys, omit } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateGroupMutation,
  useUpdateGroupMutation
} from '~/apollo/gql-types';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import { formToData, GROUPS_FORM_BASE_OPTIONS } from '../utils';

export const useFormSubmit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getHref = useHrefCallback();

  const [createGroupMutation] = useCreateGroupMutation({
    ...GROUPS_FORM_BASE_OPTIONS,
    refetchQueries: ['SearchUsers', 'SearchGroupData'],
    onCompleted({ group: { id } }) {
      notification.success({
        message: t('bo.admin.groupsForm.notifications.create.success')
      });
      navigate(getHref('GROUP', { id }));
    }
  });

  const [updateGroupMutation] = useUpdateGroupMutation({
    ...GROUPS_FORM_BASE_OPTIONS,
    refetchQueries: ['SearchUsers', 'SearchGroupData'],
    onCompleted() {
      notification.success({
        message: t('bo.admin.groupsForm.notifications.update.success')
      });
    }
  });

  const createGroup = useCallback(
    async (data) => {
      const { assignUsers, ...payload } = omit('unassignUsers')(
        formToData(data)
      );
      await createGroupMutation({
        variables: {
          group: { ...payload, userIds: keys(assignUsers) }
        }
      });
    },
    [createGroupMutation]
  );

  const updateGroup = useCallback(
    async (data) => {
      const { id, roleIds, assignUsers, unassignUsers, ...group } =
        formToData(data);
      const assignUserIds = keys(assignUsers);
      const unassignUserIds = keys(unassignUsers);

      await updateGroupMutation({
        variables: {
          id,
          roleIds,
          group,
          assignUserIds,
          unassignUserIds,
          withAssignedUsers: !!assignUserIds.length,
          withUnassignedUsers: !!unassignUserIds.length
        }
      });
    },
    [updateGroupMutation]
  );

  return useMemo(
    () => (id ? updateGroup : createGroup),
    [createGroup, id, updateGroup]
  );
};
