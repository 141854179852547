import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { withPermissions } from '@retail/backoffice-ui/src/Permission/withPermissions';
import { LinkButton } from '@retail/backoffice-urls';
import { useSearchParamsController } from '@retail/hooks';
import { Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSearchUsersQuery } from '~/apollo/gql-types';
import { PaginationRow } from '~/components/PaginationRow';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { USER_ACCESS_DOMAIN } from '~/constants/user';
import { useHrefCallback } from '~/hooks/useHrefCallback';
import {
  getSearchUsersVariables,
  SearchUsersVariablesProps
} from '~/utils/getSearchUsersVariables';

import { useFormattedOverviewData } from './hooks/useFormattedOverviewData';
import { useOverviewColumns } from './hooks/useOverviewColumns';
import { SearchForm } from './SearchForm';
import cn from './styles.less';
import { DEFAULT_SORTING } from './utils';

export const UsersOverview = withPermissions({
  allow: PERMISSIONS.QUERY_SEARCH_USERS,
  domain: DOMAINS.USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const getHref = useHrefCallback();
  const columns = useOverviewColumns();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<SearchUsersVariablesProps>(
      ({ queryString, navigate }) =>
        navigate(`${getHref('USERS')}${queryString}`)
    );

  const { data, loading: isLoading } = useSearchUsersQuery({
    ...USER_ACCESS_DOMAIN,
    skip: !controller.text,
    variables: {
      search: getSearchUsersVariables({
        ...controller,
        defaultSorts: DEFAULT_SORTING
      })
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });

  const formattedData = useFormattedOverviewData(data?.data?.entities);

  return (
    <Layout>
      <LayoutHeader title={t('bo.admin.usersOverview.title')}>
        <PermissionChecker
          domain={DOMAINS.USER_MANAGEMENT}
          allow={PERMISSIONS.MUTATION_CREATE_USER}
        >
          <LinkButton type="primary" to={getHref('USER_CREATION')}>
            {t('bo.admin.usersOverview.buttons.createUser')}
          </LinkButton>
        </PermissionChecker>
      </LayoutHeader>

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            text={controller.text}
            enabled={controller.enabled}
            countryAccess={controller.countryAccess}
            isLoading={isLoading}
            onSubmit={updateController}
          />
          {controller.text && (
            <>
              <PaginationRow
                showTotalResults
                isLoading={isLoading}
                onChange={onPageChange}
                current={controller.page}
                total={controller.totalPages}
                pageSize={controller.pageSize}
              />
              <Table
                rowKey="id"
                dataSource={formattedData}
                columns={columns}
                loading={!data?.data?.entities}
                pagination={false}
              />
              <PaginationRow
                isLoading={isLoading}
                onChange={onPageChange}
                current={controller.page}
                total={controller.totalPages}
                pageSize={controller.pageSize}
              />
            </>
          )}
        </Space>
      </LayoutBody>
    </Layout>
  );
});
