import { SortType } from '@retail/gql-utils';
import { ControllerModel, useSearchParamsController } from '@retail/hooks';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useCallback } from 'react';

import { useSearchUsersQuery } from '~/apollo/gql-types';
import { USER_ACCESS_DOMAIN } from '~/constants/user';
import { useHrefCallback } from '~/hooks/useHrefCallback';
import { SearchUserModel } from '~/types/SearchUserModel';
import { getSearchUsersVariables } from '~/utils/getSearchUsersVariables';

import { UserTeamsSearchModel } from '../types';

export enum SortOrder {
  Ascend = 'ascend',
  Descend = 'descend'
}

export const DEFAULT_VALUES: Omit<
  Required<UserTeamsSearchModel>,
  keyof ControllerModel
> &
  Pick<UserTeamsSearchModel, keyof ControllerModel> = {
  sort: null,
  direction: null,
  text: null,
  country: null,
  team: null,
  department: null
};

export function useDataSource() {
  const getHref = useHrefCallback();
  const {
    controller,
    formValues,
    onPageChange,
    updateController,
    clearController
  } = useSearchParamsController<UserTeamsSearchModel>(
    ({ queryString, navigate }) =>
      navigate(`${getHref('USER_TEAMS')}${queryString}`),
    DEFAULT_VALUES
  );
  const { data, loading: isLoading } = useSearchUsersQuery({
    ...USER_ACCESS_DOMAIN,
    variables: {
      search: getSearchUsersVariables({
        ...controller,
        enabled: true
      })
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });

  const dataSource: SearchUserModel[] = data?.data?.entities ?? [];

  const onChange = useCallback(
    (
      pagination,
      filters,
      sorter: SorterResult<SearchUserModel>,
      extra: TableCurrentDataSource<SearchUserModel>
    ) => {
      const { action } = extra;
      const { order, field } = sorter;

      if (action === 'sort' && order) {
        updateController({
          page: 1,
          sort: field,
          direction: order === SortOrder.Ascend ? SortType.ASC : SortType.DESC
        } as UserTeamsSearchModel);
      } else {
        updateController({
          page: 1,
          sort: undefined,
          direction: undefined
        } as UserTeamsSearchModel);
      }
    },
    [updateController]
  );

  return {
    dataSource,
    controller,
    isLoading,
    formValues,
    onPageChange,
    updateController,
    clearController,
    onChange
  };
}
