import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { GetRoleInfoQuery } from '~/apollo/gql-types';
import { Link } from '~/components/Link';
import { WideSpace } from '~/components/WideSpace';
import { useHrefCallback } from '~/hooks/useHrefCallback';

import cn from './styles.less';

interface Props {
  items: GetRoleInfoQuery['users']['entities'];
  loading: boolean;
}

export function RoleUsers({ items, loading }: Props) {
  const { t } = useTranslation();
  const getHref = useHrefCallback();

  return (
    <Card
      title={t('bo.admin.roleItem.form.roleUsers.title')}
      loading={loading}
      data-qa-selector-name="roleUsers"
    >
      <WideSpace direction="vertical" size="small">
        {items?.map((x) => (
          <Link
            key={x.id}
            to={getHref('USER', { id: x.id })}
            data-qa-selector-name={`roleUser-${x.id}`}
          >
            <Button type="text" block className={cn.linkButton}>
              {x.firstName} {x.lastName} ({x.email})
            </Button>
          </Link>
        ))}
      </WideSpace>
    </Card>
  );
}
