import {
  createFilter,
  createFilterPayload,
  Filter,
  FilterType,
  SortType
} from '@retail/gql-utils';
import { isString, trim } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  PageDto_UserProjection,
  useSearchUsersLazyQuery
} from '~/apollo/gql-types';
import {
  EMPTY_UUID,
  REGISTERED_FROM_BACKOFFICE_AND_ADMIN_FILTER
} from '~/constants/common';
import { GROUPS_FORM_BASE_OPTIONS } from '~/pages/GroupForm/utils';

const DEFAULT_PAYLOAD = {
  page: 0,
  pageSize: 50,
  sorts: [
    {
      property: 'firstName',
      direction: SortType.ASC
    },
    {
      property: 'lastName',
      direction: SortType.ASC
    }
  ]
};

const getSearchUserByFieldsFilter = (searchText) =>
  createFilter(null, FilterType.OR, [
    createFilter('email', FilterType.LIKE, searchText),
    createFilter('firstName', FilterType.LIKE, searchText),
    createFilter('lastName', FilterType.LIKE, searchText)
  ]);

export type TSearchParams = {
  page?: number;
  search?: string;
};

export interface IUseSearchUsers {
  searchAssignedUsers: (
    params: TSearchParams
  ) => Promise<PageDto_UserProjection>;
  searchUnassignedUsers: (
    params: TSearchParams
  ) => Promise<PageDto_UserProjection>;
}

export function useSearchUsers(): IUseSearchUsers {
  const { id: groupId = EMPTY_UUID } = useParams();
  const [fetchAssignedUsers] = useSearchUsersLazyQuery({
    ...GROUPS_FORM_BASE_OPTIONS
  });
  const [fetchUnassignedUsers] = useSearchUsersLazyQuery({
    ...GROUPS_FORM_BASE_OPTIONS
  });

  const searchAssignedUsers = useCallback(
    async ({ page, search }) => {
      const searchPayload = createFilterPayload<Filter[]>({
        ...DEFAULT_PAYLOAD,
        filter: createFilter(null, FilterType.AND, [
          REGISTERED_FROM_BACKOFFICE_AND_ADMIN_FILTER,
          createFilter('groups.id', FilterType.EQUAL, groupId),
          createFilter('enabled', FilterType.EQUAL, true)
        ]),
        page
      });

      if (isString(search) && trim(search).length > 0) {
        searchPayload.filter.value.push(getSearchUserByFieldsFilter(search));
      }

      const {
        data: { data }
      } = await fetchAssignedUsers({
        variables: {
          search: searchPayload
        }
      });

      return data as PageDto_UserProjection;
    },
    [groupId, fetchAssignedUsers]
  );

  const searchUnassignedUsers = useCallback(
    async ({ page, search }) => {
      const searchPayload = createFilterPayload<Filter[]>({
        ...DEFAULT_PAYLOAD,
        filter: createFilter(null, FilterType.AND, [
          REGISTERED_FROM_BACKOFFICE_AND_ADMIN_FILTER,
          createFilter('custom.groups.id', FilterType.NOT_EQUAL, groupId),
          createFilter('enabled', FilterType.EQUAL, true)
        ]),
        page
      });

      if (isString(search) && trim(search).length > 0) {
        searchPayload.filter.value.push(getSearchUserByFieldsFilter(search));
      }

      const {
        data: { data }
      } = await fetchUnassignedUsers({
        variables: {
          search: searchPayload
        }
      });

      return data as PageDto_UserProjection;
    },
    [groupId, fetchUnassignedUsers]
  );

  return useMemo(
    () => ({
      searchAssignedUsers,
      searchUnassignedUsers
    }),
    [searchAssignedUsers, searchUnassignedUsers]
  );
}
