import { ExtractRouteParams } from '@retail/backoffice-urls';
import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { PathName, PathRecord, PATHS } from '~/constants/route';

export function useHrefCallback() {
  const { language, platform } = useParams<Record<string, string>>();

  return useCallback(
    <Key extends PathName>(
      path: Key,
      options?: Omit<Partial<ExtractRouteParams<PathRecord[Key]>>, 'language'>
    ) => generatePath<string>(PATHS[path], { language, platform, ...options }),
    [language, platform]
  );
}
