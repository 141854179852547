import { DownloadOutlined } from '@ant-design/icons';
import {
  Layout,
  LayoutBody,
  LayoutHeader
} from '@retail/backoffice-ui/src/Layout';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { LinkButton } from '@retail/backoffice-urls';
import { Button, Col, Form, Row, Space } from 'antd';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DOMAINS } from '~/constants/permissions';
import { useHrefCallback } from '~/hooks/useHrefCallback';
import { useRoleOptions } from '~/pages/GroupForm/hooks/useRoleOptions';
import { useSyncFormValues } from '~/pages/GroupForm/hooks/useSyncFormValues';

import { AssignRoles } from './AssignRoles';
import AssignUsers from './AssignUsers';
import { GroupDetails } from './GroupDetails';
import { useDownloadAssignedUsers } from './hooks/useDownloadAssignedUsers';
import { useFormSubmit } from './hooks/useFormSubmit';
import { useGroupData } from './hooks/useGroupData';
import { useInitialValues } from './hooks/useInitialValues';
import { usePermissions } from './hooks/usePermissions';
import cn from './styles.less';

export const GroupForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { options: roleOptions, isLoaded: roleOptionsLoaded } =
    useRoleOptions();
  const { pagePermissions, submitPermissions } = usePermissions(!!id);
  const { data: groupData, isLoading: isGroupDataLoading } = useGroupData();
  const initialValues = useInitialValues(groupData);
  const downloadAssignedUsers = useDownloadAssignedUsers();
  const getHref = useHrefCallback();

  const form = useForm({ defaultValues: initialValues });
  const isEditForm = !!id;
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty }
  } = form;
  const disableForm = isGroupDataLoading || !roleOptionsLoaded || isSubmitting;

  useSyncFormValues({ initialValues, optionsLoaded: roleOptionsLoaded }, reset);

  const onSubmit = useFormSubmit();

  const title = useMemo(
    () =>
      id
        ? `${t('bo.admin.groupsForm.edit.title')}: ${
            groupData?.name || t('bo.admin.loading')
          }`
        : t('bo.admin.groupsForm.create.title'),
    [id, groupData, t]
  );

  return (
    <PermissionChecker
      domain={DOMAINS.GROUP_USER_MANAGEMENT}
      allow={pagePermissions}
    >
      <Layout className={cn.pageContainer}>
        <LayoutHeader title={title} className={cn.titleContainer}>
          <LinkButton type="link" to={getHref('GROUPS')}>
            {t('bo.admin.buttons.goToList')}
          </LinkButton>
        </LayoutHeader>

        <LayoutBody>
          <FormProvider {...form}>
            <Form disabled={disableForm} onSubmitCapture={null}>
              <Space direction="vertical" size="large">
                <GroupDetails loading={isGroupDataLoading} />

                <AssignRoles
                  options={roleOptions}
                  isLoading={!roleOptionsLoaded}
                />

                <AssignUsers />

                <PermissionChecker
                  domain={DOMAINS.GROUP_USER_MANAGEMENT}
                  allow={submitPermissions}
                >
                  <Row justify="end" gutter={24}>
                    {isEditForm && (
                      <Col>
                        <Button
                          type="ghost"
                          icon={<DownloadOutlined />}
                          data-qa-selector-name="downloadAssignedUsers"
                          onClick={() => downloadAssignedUsers(groupData?.name)}
                        >
                          {t(
                            'bo.admin.groupsForm.buttons.downloadAssignedUsers'
                          )}
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <Button
                        type="ghost"
                        disabled={!isDirty}
                        data-qa-selector-name="reset"
                        onClick={() => reset()}
                      >
                        {t('bo.admin.buttons.reset')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        htmlType="submit"
                        type="primary"
                        data-qa-selector-name="submit"
                        disabled={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {t('bo.admin.buttons.save')}
                      </Button>
                    </Col>
                  </Row>
                </PermissionChecker>
              </Space>
            </Form>
          </FormProvider>
        </LayoutBody>
      </Layout>
    </PermissionChecker>
  );
};
